<template>
  <div class="step">
    <!--Title-->
    <div class="step__title">
      <h4>会員登録が完了しました。</h4>
      <p>ChibiJobにご登録いただきありがとうございます！<br>
        <br>
        ChibiJobはお得な広告がたくさん☆<br>
        早速お好みの広告を見つけてポイントゲットしちゃおう！</p>
    </div>

    <!--button submit-->
    <router-link :to="{ name: 'Index' }"
                v-if="!isHasGiftCodeInput"
                 v-slot="{ navigate }"
                 custom>
      <button @click="navigate"
              class="btn btn__submit">
        ホームへ
        <ChevronRightIcon size="24"/>
      </button>
    </router-link>

    <!--button back to detail gift code page-->
    <button
      @click="onBackToGiftCodeDetail"
      class="btn btn__submit"
      v-if="isHasGiftCodeInput"
    >
      Chibijobギフトへ戻る
      <ChevronRightIcon size="24"/>
    </button>

    <!-- MODAL VERIFY AUTHEN MOBILE -->
    <ModalVerifyAuthenMobile @close-modal="flagModalVerifyAuth = false"
                              v-if="flagModalVerifyAuth"
                              :isSkip="true"
                              :titleTop="authTitleTop"
                              :titleBottom="authTitleBottom"/>

    <!-- MODAL NOTIFY -->
    <Modal @close-modal="turnOffNoti"
           class="modal-center modal-exchange-error"
           v-if="flagModalNoti">
      <div slot="body">
        <p v-html="messageNotification"/>

        <!-- button in modal ostiaries_error -->
        <button v-if="this.$route.meta.ostiaries_error"
                @click.prevent="turnOffNoti"
                v-html="(this.$route.meta.ostiaries_error === 'true') ? '番号入力画面に戻る' : '完了'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- tracking session -->
    <img v-if="IDParameter"
         :src="`https://px.a8.net/a8fly/earnings?a8=${IDParameter}&pid=s00000024135001&so=${profile.id}&si=1-1-1-chibijob&currency=JPY`"
         width="1" height="1" />
  </div>
</template>
<script>
import store from '@/store'
import Vue from 'vue'
import { mapState } from 'vuex'
import { ChevronRightIcon } from 'vue-feather-icons'
import { giftCodeStorageKey } from '@/enum/gift-code'
import Cookie from 'js-cookie'
import Modal from '@/components/advertising-detail/Modal'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'

export default {
  name: 'Step03',

  components: {
    ChevronRightIcon,
    Modal,
    ModalVerifyAuthenMobile
  },

  async beforeRouteEnter (to, from, next) {
    to.meta.ostiaries_error = to.query.ostiaries_error || undefined
    window.scrollTo({ top: 0, behavior: 'smooth' })
    await store.dispatch('auth/userProfile')
    next()
  },

  beforeRouteLeave (to, from, next) {
    this.destroySmaScripts()
    const foundSmaImg = document.querySelector('img[src*="https://tr.smaad.net/script?cid"]')
    if (foundSmaImg) foundSmaImg.remove()
    next()
  },

  created () {
    if (this.$route.meta.ostiaries_error) {
      this.messageNotification = (this.$route.meta.ostiaries_error === 'true')
        ? '着信認証が完了しませんでした。<br/>再度お試しください。' : '電話番号の認証が完了しました！'
      this.flagModalNoti = true
    }
  },

  mounted () {
    if (!this.profile.otp) {
      this.flagModalVerifyAuth = true
    }
    this.initSmaScripts()
  },

  computed: {
    ...mapState('auth', ['profile']),

    giftCodeData () {
      return Cookie.get(giftCodeStorageKey) || null
    },

    isHasGiftCodeInput () {
      return !!this.giftCodeData
    }
  },

  data () {
    return {
      flagModalVerifyAuth: false,
      flagModalNoti: false,
      messageNotification: '',
      IDParameter: Cookie.get('a8_custom'),
      authTitleTop: '電話番号による認証を完了すると、ポイントを交換することができるようになります。',
      authTitleBottom: '電話番号認証はスキップ可能ですが、ポイント交換を実施するまでに行っていただく必要がございます。',
      tpSrc: '//static.smaad.net/script/tp.js'
    }
  },

  methods: {
    async initSmaScripts () {
      await Vue.loadScript(this.tpSrc)

      const cidContent = `_smaad({
        "cid": "919738443",
        "ec": "${this.profile.id}"
      });`

      await Vue.loadScript('', null, null, cidContent)
    },
    async destroySmaScripts () {
      await Vue.unloadScript(this.tpSrc)

      const scripts = document.body.querySelectorAll('script')
      const cidScript = Object.values(scripts).find((script) => {
        const content = script.innerHTML
        return content.includes('cid') && content.includes('919738443')
      })
      if (cidScript) cidScript.remove()
    },

    onBackToGiftCodeDetail () {
      const giftCode = Cookie.get(giftCodeStorageKey) || null
      Cookie.remove(giftCodeStorageKey)
      this.$router.push({
        name: 'GiftCodeDetail',
        params: {
          code: giftCode
        }
      })
    },

    turnOffNoti () {
      // remove query url from ostiaries
      if (window.location.href.includes('?ostiaries_error')) {
        this.$router.replace({
          name: this.$route.name
        })
      }
      this.flagModalNoti = false
    }
  }
}
</script>

<style lang="scss" scoped>
.step {
  padding: 65px;
  @media screen and (max-width: 768px) {
    padding: 48px 18px;
  }

  &__title {
    text-align: center;

    h4 {
      font-family: $font-family-title;
      @include font-size(24px);
      color: #7C934C;
      margin-bottom: 20px;
      @media screen and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 16px;
      }
    }

    p {
      @include font-size(18px);
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 50px;
      @media screen and (max-width: 768px) {
        text-align: left;
        margin-bottom: 40px;
      }
    }
  }

  .btn__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    background: #6F8784;
    font-family: $font-family-title;
    @include font-size(18px);
    padding: 14px;
    margin: auto;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      padding: 10px;
      max-width: 100%;
    }
    svg {
      margin-left: 5px;
    }
  }
}

.modal-notify {
  @include font-size(18px);
  font-weight: bold;
}
</style>
